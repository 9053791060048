<template>
  <van-action-sheet v-model="visible">
    <div class="address-dialog">
      <div class="edit-address-header">
        <van-nav-bar
          title="地址列表"
          :left-arrow="visibles.edit"
          :right-text="visibles.edit?'':'添加 '"
          @click-left="visibles.edit=false"
          @click-right="showEdit()"
        />
      </div>
      <div v-if="visibles.edit" class="edit-address">
        <van-cell-group inset>
          <van-field v-model="model.contact" type="text" label="联系人"/>
          <van-field v-model="model.mobile" type="tel" label="联系方式"/>
          <van-field label="地区信息">
            <template #input>
              <div @click="$refs.pcasPicker.show()">
                <div v-if="!model.province && !model.city && !model.district && !model.street" style="color: #999">请选择
                </div>
                <div v-else>{{ model.province }} {{ model.city }} {{ model.district }} {{ model.street }}</div>
              </div>
            </template>
          </van-field>
          <van-field type="text" label="详细地址" v-model="model.address"/>
        </van-cell-group>
        <div>
          <div class="save-address-btn" @click="save">保存</div>
        </div>
      </div>


      <div class="address-list" v-else-if="addresses && addresses.length">
        <div class="address-item" v-for="item in addresses">
          <div :class="`coupon-select-btn ${addressId===item.id?'active':''}`" @click="chooseAddress(item)">
            <van-icon name="success" style="transform: translateY(1px)"/>
          </div>
          <div>
            <div class="address-contact">{{ item.contact }} {{ item.mobile }}</div>
            <div class="address-info">{{ item.province }}{{ item.city }}{{ item.district }}{{ item.street }}</div>
            <div class="address-info">{{ item.address }}</div>
          </div>
          <div @click="showEdit(item)">
            <van-icon name="edit"/>
          </div>
        </div>
      </div>
      <div v-else class="empty" style="height: 50vh">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/case-empty.svg" alt="">
        <div>
          <van-icon name="location-o"/>
          暂无已保存的地址~
        </div>
      </div>

    </div>
    <pcas-picker ref="pcasPicker" :province="model.province" @change="onPcasChange"/>
  </van-action-sheet>
</template>


------------------ script ------------------
<script>
import PcasPicker from "./pcasPicker";
import {addressV2Api} from "../../api/v2/addressV2Api";
import {Toast} from "vant";

export default {
  components: {PcasPicker},
  props: ['addressId'],
  data() {
    return {
      visible: false,
      visibles: {
        edit: false
      },
      model: {
        id: '',
        province: '',
        city: '',
        district: '',
        street: '',
        contact: '',
        mobile: '',
        address: '',
      },
      addresses: []
    }
  },


  async created() {
    await this.loadAddress();
    this.chooseAddress(this.addresses[0]);
  },

  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },

    // 加载地址
    async loadAddress() {
      this.addresses = await addressV2Api.findMy()
    },

    // 选择地址
    chooseAddress(item) {
      this.$emit('chooseAddress', item);
      this.hide();
    },

    onPcasChange(e) {
      this.model.province = e[0]
      this.model.city = e[1]
      this.model.district = e[2]
      this.model.street = e[3]
      this.$forceUpdate()
    },

    async showEdit(model) {
      model = model || {
        province: '',
        city: '',
        district: '',
        street: ''
      }
      this.model = {...model}
      this.visibles.edit = true;
    },

    async save() {
      try {
        await addressV2Api.save(this.model);
        Toast.success('保存成功');
        await this.loadAddress();
        this.visibles.edit = false;
      } catch (e) {
        Toast.fail(e.message || '操作失败')
      }
    },
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.address-dialog {
  height: 80vh;
  background: #f7f7f7;
}

.edit-address-header {
  height: 50px;
  background: #fff;
  margin-bottom: 16px;
}

.edit-address {

}

.save-address-btn {
  height: 50px;
  width: 90vw;
  border-radius: 100px;
  background: #151D36;
  color: #fff;
  font-size: 14px;
  margin: 16px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-item {
  margin: 16px;
  padding: 16px;
  background: #fff;
  line-height: 20px;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coupon-select-btn {
  height: 24px;
  width: 24px;
  border: 1px solid #e9e9e9;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  flex-shrink: 0;

  &.active {
    background: #151D36;
  }
}

</style>
