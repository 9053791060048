import {v2Request} from "./v2Request";


export const goodsCouponRecordV2Api = {

  // 兑换优惠券
  async exchange(code) {
    return v2Request.post(`goodsCouponRecord/exchange`, {code})
  },

  // 搜索
  async searchMy(query) {
    query.page = 1;
    query.size = 1000
    return v2Request.get(`goodsCouponRecord/searchMy`, {params: query});
  }

}
