<template>
  <div :class="noPadding?`no-padding`:''">

    <div class="model-con" v-if="showDialog" :style="zIndex?'z-index:'+zIndex:''">

      <div class="mask" @click="closeModal" :style="zIndex?'z-index:'+(zIndex+1):''"></div>

      <div class="toast-wrap" v-if="showDialog" :class="(showDialog?'bounce':'hide') + ' ' +popperClass"
           :style="zIndex?'z-index:'+(zIndex+2):''">
        <div class="close btn" v-if="showClose" @click="toggleDialog">×</div>
        <div class="toast-content">
          <slot/>
        </div>
        <div class="click-wrap" v-if="showBtn">
          <div class="cancel" @click="cancelDoSomeThing">取消</div>
          <div class="sure" @click="sureDoSomeThing">确定</div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "model",
  props: {
    showBtn: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      required: false
    },
    noPadding: {
      type: Boolean,
      required: false
    },
    popperClass: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    show() {
      this.showDialog = true
    },
    hide() {
      this.showDialog = false
    },
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
    cancelDoSomeThing() {
      this.$emit('cancel');
    },
    sureDoSomeThing() {
      this.$emit('confirm');
    },

    closeModal() {
      if (!this.canClose) return;
      this.showDialog = false;
    }
  }
}
</script>

<style scoped lang="less">
/* 模态框 */
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9001;
}

.model-con {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
}

.toast-wrap {
  width: 320px;
  background-color: #fff;
  border-radius: 5px;
  padding-top: 25px;
  position: relative;
  z-index: 9002;
  transition: opacity .3s ease;
}

.toast-content {
  padding: 22px;
  padding-top: 10px;
  position: relative;
}

.no-padding {
  .toast-content {
    padding: 0;
    padding-top: 0;
  }

  .toast-wrap {
    padding-top: 0;
  }
}

.click-wrap {
  width: 100%;
  height: 45px;
  line-height: 45px;
  display: flex;
  font-size: 16px;
  border-top: 1px solid #f2f2f2;
  box-sizing: border-box;
}

.click-wrap .cancel {
  width: 50%;
  height: 100%;
  line-height: 45px;
  text-align: center;
  border-right: 1px solid #f2f2f2;
  box-sizing: border-box;
}

.click-wrap .sure {
  width: 50%;
  height: 100%;
  line-height: 45px;
  text-align: center;
  color: #ef4631;
}

.toast-wrap .close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 20px;
  line-height: 100%;
}
</style>
