<template>
  <div class="input-number">
    <div :class="`input-number-btn ${cannotMinus?'disabled':''}`" @click="minus">
      <van-icon name="minus"/>
    </div>
    <input class="input-number-ipt" type="number" :value="value" @input="onInput">
    <div :class="`input-number-btn ${cannotAdd?'disabled':''}`" @click="add">
      <van-icon name="plus"/>
    </div>
  </div>
</template>


------------------ script ------------------
<script>
export default {
  props: ['value', 'min', 'max'],

  computed: {
    cannotAdd() {
      const max = this.max || Number.MAX_VALUE;
      return this.value >= max
    },
    cannotMinus() {
      const min = this.min || -Number.MAX_VALUE
      return this.value <= min
    }
  },

  methods: {
    onInput(e) {
      let value = parseInt(e.target.value);
      const min = this.min || -Number.MAX_VALUE
      const max = this.max || Number.MAX_VALUE
      if (value > max) {
        value = max
      } else if (value < min) {
        value = min
      }

      this.$emit('input', value);
    },

    add() {
      const value = this.value + 1;
      this.onInput({target: {value}})
    },


    minus() {
      let value = this.value - 1;
      this.onInput({target: {value}})
    },
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.input-number {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.input-number-btn {
  background: #151D36;
  color: #fff;
  font-size: 12px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  border-radius: 100px;
  flex-shrink: 0;

  &.disabled {
    background: #e9e9e9;
  }
}

.input-number-ipt {
  display: inline-block;
  width: 50px;
  border: none;
  text-align: center;
}
</style>
