<template>
  <van-action-sheet v-model="visible">
    <div class="goods-coupon-dialog">
      <div class="coupon-exchange">
        <input v-model="model.code" type="text" placeholder="请输入兑换码">
        <div class="coupon-exchange-btn" @click="exchange">兑换</div>
      </div>
      <div v-if="!coupons ||!coupons.length" class="empty">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/case-empty.svg" alt="">
        <div>暂无可使用的优惠券</div>
      </div>
      <div v-else class="coupons">
        <div class="coupon-item" v-for="item in coupons">
          <div class="coupon-main">
            <div class="coupon-value">
              <div v-if="item.type === 1">{{ item.value }} <span class="coupon-unit">元</span></div>
              <div v-else-if="item.type === 2">{{ item.value }} <span class="coupon-unit">折</span></div>
              <div class="coupon-unit">无使用门槛</div>
            </div>
            <div class="coupon-info">
              <div>
                <div class="coupon-name">{{ item.name }}</div>
                <div class="coupon-expire">{{ item.gmtExpire }}到期</div>
              </div>
              <div :class="`coupon-select-btn ${couponId===item.id?'active':''}`" @click="chooseCoupon(item)">
                <van-icon name="success" style="transform: translateY(2px)"/>
              </div>
            </div>
          </div>
          <div class="coupon-desc">{{ item.desc }}</div>
        </div>
        <div class="not-use-coupon-btn">
          不使用优惠券
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>


------------------ script ------------------
<script>
import Model from "../model/model";
import {goodsCouponRecordV2Api} from "../../api/v2/goodsCouponRecordV2Api";
import {Toast} from "vant";

export default {
  components: {Model},
  props: ['coupons','couponId'],
  data() {
    return {
      visible: false,
      model: {
        code: ''
      }
    }
  },

  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },

    async exchange() {
      if (!this.model.code) {
        return Toast.fail('请填写兑换码')
      }
      try {
        await goodsCouponRecordV2Api.exchange(this.model.code)
        Toast.success('兑换成功');
        this.$emit('exchangeSuccess');
      } catch (e) {
        return Toast.fail(e.message || '操作失败');
      }
    },

    chooseCoupon(item) {
      this.$emit('chooseCoupon', item);
      this.hide()
    }

  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.goods-coupon-dialog {
  height: 80vh;
  padding-top: 60px;
}

.coupon-exchange {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;

  input {
    flex-grow: 1;
    border: none;
    padding: 12px 16px;
    border-radius: 100px;
    background: #f3f5f7;
  }

  .coupon-exchange-btn {
    padding: 0 8px;
    font-size: 14px;
  }
}

.coupons {
  background: #f7f8fa;
  padding: 16px 16px 72px 16px;
}

.coupon-item {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 4px #e9e9e9;
  margin-bottom: 8px;
}


.coupon-main {
  display: flex;
  align-items: center;
}

.coupon-value {
  width: 100px;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 28px;
  color: #ee4a50;
}

.coupon-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  padding-right: 16px;
}

.coupon-unit {
  font-size: 12px;
  color: #ee4a50;
}

.coupon-expire {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}

.coupon-desc {
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid #e9e9e9;
}

.coupon-select-btn {
  height: 24px;
  width: 24px;
  border: 1px solid #e9e9e9;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  &.active{
    background: #ee4a50;
  }
}

.not-use-coupon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  background: #ee4a50;
  color: #fff;
  border-radius: 100px;
  height: 50px;
  font-size: 14px;
  margin: 16px auto 0 auto;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.empty {
  height: 60vh;
}
</style>
