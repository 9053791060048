<template>
  <van-action-sheet v-model="visible">
    <van-cascader
      title="请选择所在地区"
      :options="options"
      :value="province||'北京市'"
      :field-names="fieldNames"
      @close="visible = false"
      @change="onChange"
    />
  </van-action-sheet>
</template>


------------------ script ------------------
<script>
import {pcasV2Api} from "../../api/v2/pcasV2Api";

export default {
  props: ['province'],
  data() {
    return {
      visible: false,
      fieldNames: {
        text: 'name',
        value: 'name',
        children: 'children'
      },
      options: [],
    }
  },

  created() {
    this.loadProvince();
  },

  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },


    async loadProvince() {
      this.options = await pcasV2Api.findByParentCode(0);
      console.log(this.options)
    },


    async onChange(e) {
      if (e.tabIndex < 3) {
        const option = e.selectedOptions[e.selectedOptions.length - 1];
        option.children = await pcasV2Api.findByParentCode(option.code);
      } else {
        const value = e.selectedOptions.map(o => o.name)
        this.$emit('change', value)
        this.hide();
      }
    }
  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped></style>
