<template>
  <div class="order-page">
    <v-header/>
    <div class="order-card" @click="$refs.addressDialog.show()">
      <div class="order-title">收货信息</div>
      <div class="address">
        <div v-if="model.address.addressId">
          <div class="address-name">{{ model.address.contact }} {{ model.address.mobile }}</div>
          <div class="address-info"> {{ model.address.province }}{{ model.address.city }}{{
              model.address.district
            }}{{ model.address.street }} {{ model.address.address }}
          </div>
        </div>
        <div v-else class="address-info" style="margin-top: 0">
          <van-icon name="location-o"/>
          暂无已保存的地址~
        </div>
        <van-icon name="arrow"/>
      </div>
    </div>

    <div class="order-card">
      <div class="order-title">商品信息</div>
      <div>
        <router-link :to="`/shop/goods/${goods.id}`" class="goods-info">
          <img class="goods-cover" :src="goods.cover" alt="">
          <div>
            <div class="goods-title">{{ goods.title }}</div>
            <div class="goods-desc">{{ goods.desc }}</div>
          </div>
        </router-link>
        <div class="goods-prop">
          <div class="goods-prop-label">商品单价</div>
          <div class="goods-prop-value red">¥{{ goods.price }}</div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">购买数量</div>
          <div class="goods-prop-value">
            <input-number :min="1" :max="goods.canBuyTimes" v-model="model.quantity"/>
          </div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">小计</div>
          <div class="goods-prop-value red">¥{{ goods.price }}</div>
        </div>
      </div>
    </div>


    <div class="order-card">
      <div class="order-title">
        <div>优惠券</div>
        <div class="order-title-btn" @click="$refs.goodsCouponDialog.show()">兑换</div>
      </div>
      <div v-if="!coupons ||!coupons.length" class="empty">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/case-empty.svg" alt="">
        <div>暂无可使用的优惠券</div>
      </div>
      <div v-else-if="!coupon" class="coupon-cell" @click="$refs.goodsCouponDialog.show()">
        <div>{{ coupons.length }}张可用</div>
        <van-icon name="arrow"/>
      </div>
      <div v-else class="coupon-cell" @click="$refs.goodsCouponDialog.show()">
        <div>
          {{ coupon.name }}
          <template v-if="coupon.type === 1">直减{{ coupon.value }}元</template>
          <template v-else-if="coupon.type === 2">{{ coupon.value }}折</template>
        </div>
        <van-icon name="arrow"/>
      </div>
    </div>


    <div class="order-card">
      <div class="order-title">支付方式</div>
      <div class="pay-btns">
        <div :class="`pay-btn wx ${payType===payTypeEnum.wx?'active':''}`" @click="payType=payTypeEnum.wx">
          <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-green.svg" alt=""
               v-if="payType === payTypeEnum.wx">
          <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-gray.svg" alt="" v-else>
          微信支付
        </div>
        <div :class="`pay-btn alipay ${payType===payTypeEnum.alipay?'active':''}`" @click="payType=payTypeEnum.alipay">
          <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-blue.svg" alt=""
               v-if="payType === payTypeEnum.alipay">
          <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-gray.svg" alt="" v-else>
          支付宝支付
        </div>
      </div>
      <div class="goods-prop">
        <div class="goods-prop-label">
          <div>使用严值</div>
          <div style="color: #999;font-size:12px">使用严值将不支持退款</div>
        </div>
        <div class="goods-prop-value">
          <van-switch v-model="model.useYanzhi" size="24px" active-color="#151d36"/>
        </div>
      </div>
      <div class="goods-prop">
        <div class="goods-prop-label">剩余严值</div>
        <div class="goods-prop-value red">{{ yanzhiFilter(wallet.totalRemainValue) }}严值
          (¥{{ moneyFilter(wallet.totalRemainValue / 10) }})
        </div>
      </div>
    </div>

    <div class="pay-line">
      <div class="red">¥{{ finalPrice }}</div>
      <div class="buy-btn" @click="createOrder">去支付</div>
    </div>

    <goods-coupon-dialog :coupons="coupons" ref="goodsCouponDialog" @exchangeSuccess="loadCoupons"
                         :coupon-id="model.couponId" @chooseCoupon="onChooseCoupon"/>
    <address-dialog ref="addressDialog" :address-id="model.address.addressId" @chooseAddress="onChooseAddress"/>
  </div>
</template>


------------------ script ------------------
<script>
import {goodsV2Api} from "../../api/v2/goodsV2Api";
import InputNumber from "../../components/inputNumber";
import {goodsCouponRecordV2Api} from "../../api/v2/goodsCouponRecordV2Api";
import GoodsCouponDialog from "../../components/shop/GoodsCouponDialog";
import AddressDialog from "../../components/shop/AddressDialog";
import {mapGetters} from "vuex";
import {Toast} from "vant";
import {orderV2Api} from "../../api/v2/orderV2Api";
import {getPaySubType} from "../../config/util";

export default {
  components: {InputNumber, GoodsCouponDialog, AddressDialog},
  data() {
    return {
      payType: 1,
      payTypeEnum: {
        wx: 1,
        alipay: 2
      },
      goods: {
        id: '',
        title: '',
        desc: '',
        detail: '',
        price: '',
        cover: '',
        imgs: '',
        canBuyTimes: 1
      },
      coupons: [],
      model: {
        address: {
          province: '',
          city: '',
          district: '',
          street: '',
          contact: '',
          mobile: '',
          address: '',
          addressId: ''
        },
        useYanzhi: false,
        couponId: '',
        quantity: 1,
      }
    }
  },

  computed: {
    coupon() {
      return this.coupons.find(c => this.model.couponId === c.id);
    },

    // 最终价格
    finalPrice() {
      let finalPrice = this.moneyFilter(this.goods.price * this.model.quantity);
      const coupon = this.coupons.find(c => this.model.couponId === c.id);

      if (coupon) {
        if (coupon.type === 1) {
          finalPrice = finalPrice - coupon.value;
        } else if (coupon.type === 2) {
          finalPrice = this.moneyFilter(finalPrice * coupon.value / 10);
        }
      }

      if (this.model.useYanzhi) {
        finalPrice -= this.wallet.totalRemainValue / 10;
        finalPrice = finalPrice > 0 ? finalPrice : 0;
      }

      return finalPrice;
    },

    ...mapGetters(['wallet'])
  },

  created() {
    this.loadGoods();
    this.loadCoupons();
  },

  methods: {
    async loadGoods() {
      this.goods = await goodsV2Api.findById(this.$route.params.id);
    },

    async loadCoupons() {
      const res = await goodsCouponRecordV2Api.searchMy({goodsId: this.$route.params.id});
      this.coupons = res.rows || [];
    },


    // 选择地址
    onChooseAddress(address) {
      address = {...address};
      address.addressId = address.id
      delete address.id
      this.model.address = address;
    },

    // 选择优惠券
    onChooseCoupon(coupon) {
      this.model.couponId = coupon.id;
    },

    // 创建订单
    async createOrder() {
      try {
        if (!this.model.address.addressId) {
          return Toast.fail('请填写地址');
        }
        const model = {...this.model};
        model.goodsCouponRecordId = model.couponId;
        model.goodsId = this.$route.params.id;
        model.payType = this.payType;
        model.paySubType = getPaySubType(model.payType);
        const res = await orderV2Api.createOrder(model)
        await this.$router.push({
          path: `/shop/order/${res.orderNo}?openPay=true`,
        })
      } catch (e) {
        Toast.fail(e.message || '操作失败')
      }
    }

  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./order";
</style>
