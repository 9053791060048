import {v2Request} from "./v2Request";


export const addressV2Api = {

  findMy() {
    return v2Request.get('address/findMy')
  },

  save(model) {
    return v2Request.post(`address/save`, model);
  },


  delById(id) {
    return v2Request.post(`address/delById`, {id})
  }
}
